import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ThemeProvider,
  Button,
  Container,
  RadioGroup,
  FormControl,
  FormControlLabel,
  styled,
  TextField,
  useMediaQuery,
} from "@mui/material";
import PostSurveyView from "./PostSurvey";
import { createTheme } from "@mui/material/styles";

const StyledContainer = styled(Container)({
  padding: 0,
  textAlign: "center",
  width: "325px",
  transition: "transform 0.5s ease",
});

const QuestionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 0,
});

const OptionButton = styled(Button)(({ theme, selected }) => ({
  marginTop: 10,
  marginBottom: 5,
  width: "350px",
  height: 45,
  padding: theme.spacing(2),
  fontFamily: "'neue_hans_kendrickregular'",
  textAlign: "center",
  borderRadius: 20,
  border: `1px solid grey`,
  backgroundColor: selected ? "black" : "inherit",
  color: selected ? theme.palette.common.white : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: selected ? "black" : theme.palette.action.hover,
  },
  marginLeft: "27px",
}));

const ButtonsContainer = styled("div")({
  marginTop: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
});

const NextButton = styled(Button)({
  width: "165px",
  height: 45,
  margin: "8px",
  borderRadius: 20,
  color: "black",
  border: "1px solid black",
  fontFamily: "'neue_hans_kendrickregular'",
});

const StyledTextField = styled(TextField)({
  margin: "8px",
  width: "80%",
  "& label.Mui-focused": {
    color: "black",
  },
});
const Survey = ({ onComplete }) => {
  const [showPostSurveyView, setShowPostSurveyView] = useState(false);
  const [showEmailStep, setShowEmailStep] = useState(false); // New state to control visibility of email step

  useEffect(() => {
    if (localStorage.getItem("prompt")) {
      localStorage.removeItem("prompt");
      console.log("clearing prompot");
    }
    if (localStorage.getItem("email")) {
      localStorage.removeItem("email");
      console.log("clearing email");
    }
  }, []);

  const initialFormData = {
    steps: [
      {
        question: "How does your skin feel during the day?",
        options: [
          { label: "DRY AND ROUGH TO TOUCH", value: "dry and rough on touch" },
          { label: "TIGHT AND LOOKS DULL", value: "tight and looks dull" },
          {
            label: "OILY, WITH NOTICABLE PORES",
            value: "oily, with noticable pores",
          },
          {
            label: "SENSITIVE AND UNCOMFORTABLE",
            value: "sensitive and uncomfortable",
          },
          {
            label: "IM HAPPY WITH HOW IT FEELS",
            value: "Im happy with how it feels",
          },
        ],
      },
      {
        question: "What is your skin goal?",
        options: [
          {
            label: "SMOOTH, FIRM AND LIFTED",
            value: "smooth, firm and lifted",
          },
          {
            label: "SOFT, NOURISHED AND RADIANT",
            value: "soft, nourished and radiant",
          },
          {
            label: "BALANCED, MATIFIED AND REFINED",
            value: "balanced, matified and refined",
          },
          {
            label: "CALM AND COMFORTABLE",
            value: "calm, comfortable and soothed",
          },
          {
            label: "VISIBLY EVEN AND SMOOTH",
            value: "visibly even and smooth",
          },
        ],
      },
      {
        question: "How do you like your skincare to feel?",
        options: [
          { label: "NOURISHING", value: "nourishing" },
          { label: "LIGHTWEIGHT", value: "lighweight" },
        ],
      },
    ],
  };

  const { control, handleSubmit } = useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [email, setEmail] = useState(""); // State to store email input value
  const [validEmail, setValidEmail] = useState(false); // State to track if email is valid
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const onSubmit = async (data) => {
    if (showEmailStep) {
      setShowPostSurveyView(true);
      localStorage.setItem("email", email);
      localStorage.setItem(
        "prompt",
        `question: How does your skin feel during the day? answer:${answers[0].selectedOption}; 
          question: What is your skin goal? answer: ${answers[1].selectedOption}; 
          question: How do you like your skincare to feel? answer: ${answers[2].selectedOption}`
      );
      try {
        // Make POST request to save the email
        const response = await fetch(
          "https://mid-filler.onrender.com/save-email",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );
      } catch (error) {}
    } else {
      setShowEmailStep(true);
    }
  };

  const handleOptionSelect = (selectedOption) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[currentStep] = { selectedOption };
      return newAnswers;
    });
  };

  const handleNext = () => {
    if (answers[currentStep]?.selectedOption) {
      setSwipeDirection("left");
      setTimeout(() => {
        setSwipeDirection("right");
        setCurrentStep((prevStep) =>
          prevStep === initialFormData.steps.length - 1 ? 0 : prevStep + 1
        );
        setTimeout(() => {
          setSwipeDirection(null);
        }, 0);
      }, 500);
    }
  };

  const handlePrev = () => {
    setSwipeDirection("right");
    setTimeout(() => {
      setSwipeDirection("left");
      setCurrentStep((prevStep) =>
        prevStep === 0 ? initialFormData.steps.length - 1 : prevStep - 1
      );
      setTimeout(() => {
        setSwipeDirection(null);
      }, 0);
    }, 500);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    // Validate email with regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidEmail(emailRegex.test(emailValue));
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000", //your color
      },
    },
  });

  const handleCancel = () => {
    setShowPostSurveyView(false);
  };

  return (
    <StyledContainer
      maxWidth="sm"
      style={{
        transform: `translateX(${
          swipeDirection === "left"
            ? "200%"
            : swipeDirection === "right"
            ? "-200%"
            : "0"
        })`,
        fontFamily: "'neue_hans_kendrickregular'",
      }}
    >
      {showPostSurveyView ? (
        <PostSurveyView onCancel={handleCancel} onComplete={onComplete} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <QuestionContainer>
            {!showEmailStep ? (
              <>
                <p
                  style={{
                    fontSize: "30px",
                    marginBottom: 20,
                    fontFamily: "'neue_hans_kendrickregular'",
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                >
                  {initialFormData.steps[currentStep].question}
                </p>
                <FormControl component="fieldset">
                  <RadioGroup
                    control={control}
                    name={`steps[${currentStep}].selectedOption`}
                    value={answers[currentStep]?.selectedOption || ""}
                    onChange={(e) => handleOptionSelect(e.target.value)}
                  >
                    {initialFormData.steps[currentStep].options.map(
                      (option, optionIndex) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={
                            <OptionButton
                              selected={
                                option.value ===
                                answers[currentStep]?.selectedOption
                              }
                              onClick={() => handleOptionSelect(option.value)}
                            >
                              {option.label}
                            </OptionButton>
                          }
                          label={null}
                        />
                      )
                    )}
                  </RadioGroup>
                </FormControl>
                <ButtonsContainer>
                  <NextButton
                    variant="outlined"
                    onClick={handlePrev}
                    disabled={currentStep === 0}
                    sx={{
                      "&:hover": {
                        border: "1px solid grey",
                      },
                    }}
                  >
                    Back
                  </NextButton>

                  {currentStep < initialFormData.steps.length - 1 && (
                    <NextButton
                      variant="contained"
                      sx={{
                        backgroundColor: "black",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                        color: "white",
                        "&.Mui-disabled": {
                          background: "#909090",
                          color: "#D8DFEC",
                        },
                        border: "none",
                        fontFamily: "'neue_hans_kendrickregular'",
                      }}
                      onClick={handleNext}
                      disabled={!answers[currentStep]?.selectedOption}
                    >
                      Next
                    </NextButton>
                  )}
                  {currentStep === initialFormData.steps.length - 1 && (
                    <NextButton
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "black",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                        color: "white",
                        "&.Mui-disabled": {
                          background: "#909090",
                          color: "#D8DFEC",
                        },
                        border: "none",
                        fontFamily: "'neue_hans_kendrickregular'",
                      }}
                      disabled={!answers[currentStep]?.selectedOption}
                    >
                      Continue
                    </NextButton>
                  )}
                </ButtonsContainer>
              </>
            ) : (
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    fontSize: "30px",
                    marginBottom: 20,
                    fontFamily: "'neue_hans_kendrickregular'",
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                >
                  Enter your email to continue
                </p>

                <StyledTextField
                  id="standard-required"
                  label="Email"
                  variant="standard"
                  value={email}
                  onChange={handleEmailChange}
                  helperText={!validEmail ? "Invalid email" : ""}
                  sx={{
                    marginBottom: 5,
                    width: 335,
                    ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "black !important",
                      },
                  }}
                />
                <ButtonsContainer>
                  <NextButton
                    variant="outlined"
                    onClick={() => setShowEmailStep(false)} // Back button to go back to previous question
                    sx={{
                      "&:hover": {
                        border: "1px solid grey",
                      },
                    }}
                  >
                    Back
                  </NextButton>
                  <NextButton
                    variant="contained"
                    type="submit"
                    disabled={!validEmail} // Continue button is disabled if email is not valid
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      color: "white",
                      "&.Mui-disabled": {
                        background: "#909090",
                        color: "#D8DFEC",
                      },
                      border: "none",
                      fontFamily: "'neue_hans_kendrickregular'",
                    }}
                  >
                    Continue
                  </NextButton>
                </ButtonsContainer>
              </ThemeProvider>
            )}
          </QuestionContainer>
        </form>
      )}
    </StyledContainer>
  );
};

export default Survey;

const initialFormData = {
  steps: [
    {
      question: "How does your skin feel during the day?",
      options: [
        { label: "DRY AND ROUGH TO TOUCH", value: "dry and rough on touch" },
        { label: "TIGHT AND LOOKS DULL", value: "tight and looks dull" },
        {
          label: "OILY, WITH NOTICABLE PORES",
          value: "oily, with noticable pores",
        },
        {
          label: "SENSITIVE AND UNCOMFORTABLE",
          value: "sensitive and uncomfortable",
        },
        {
          label: "IM HAPPY WITH HOW IT FEELS",
          value: "Im happy with how it feels",
        },
      ],
    },
    {
      question: "What is your skin goal?",
      options: [
        {
          label: "SMOOTH, FIRM AND LIFTED",
          value: "smooth, firm and lifted",
        },
        {
          label: "SOFT, NOURISHED AND RADIANT",
          value: "soft, nourished and radiant",
        },
        {
          label: "BALANCED, MATIFIED AND REFINED",
          value: "balanced, matified and refined",
        },
        {
          label: "CALM AND COMFORTABLE",
          value: "calm, comfortable and soothed",
        },
        {
          label: "VISIBLY EVEN AND SMOOTH",
          value: "visibly even and smooth",
        },
      ],
    },
    {
      question: "How do you like your skincare to feel?",
      options: [
        { label: "NOURISHING", value: "nourishing" },
        { label: "LIGHTWEIGHT", value: "lighweight" },
      ],
    },
  ],
};
